import React from 'react';
import classNames from 'classnames';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';

const FooterCallout = ({ text, className }) => (
  <Section className={classNames('footer-callout', className)}>
    <Container>
      <Row>
        <Column size={6} offset={6}>
          <p className="h2 text-bold footer-callout__text">{text}</p>
        </Column>
      </Row>
    </Container>
  </Section>
);

export default FooterCallout;
