import React from 'react';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Button from '../components/Button';
import Carousel from '../components/Carousel';
import HeroV2 from '../components/HeroV2';
import FooterCallout from './partials/_FooterCallout';
import Girls4TechCallout from './partials/_Girls4TechCallout';
import CareerCallout from './partials/_CareerCallout';
import HeroVideo from '../videos/home-hero-video-2800x1000.mp4';
import Video from '../components/Video';
import { MEDIA_TYPE } from '../utils/constants';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <HeroV2 mediaType={MEDIA_TYPE.MP4_VIDEO} mediaSource={HeroVideo} />
    <Callout to="/video-topic-series" className="home__callout">
      Discover the Fastest Growing Careers in Cybersecurity
    </Callout>
    <Section>
      <Container>
        <Row>
          <Column medium={6} large={5}>
            <Video guidSrc="c5874f24-78d3-4203-b2b0-db204f527187" />
          </Column>
          <Column medium={6} large={7}>
            <h2>
              Explore New Frontiers in Tech with the Next Generation of Problem
              Solvers
            </h2>
            <p>
              Our world is changing faster than ever, and there has never been
              more opportunity for students to soar in STEM! As the 4
              <sup>th</sup> Industrial Revolution redefines life as we know it,
              students today are in a unique position to help build a better
              world for us all by harnessing the power of technological
              innovation.
            </p>
            <Button to="/about">About the Program</Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <div className="home__careers">
          <Row>
            <Column
              xsmall={9}
              xsmallOffset={3}
              medium={3}
              mediumOffset={1}
              className="home__careers-img"
            >
              <Image filename="home-careers.jpg" />
            </Column>
            <Column medium={8} large={7}>
              <h2>Helping Cyber Savvy Students Lean into STEM Success</h2>
              <p>
                Introduce students to exciting innovations in Cybersecurity,
                Artificial Intelligence, and Data Science, and meet the awesome
                STEM pros who are redefining roles and improving our world.{' '}
                <strong>Girls4Tech</strong> empowers young people to embrace
                digital connectivity and explore creative solutions to
                real-world challenges in tech.
              </p>
              <Button to="/career-profiles">Explore the Careers</Button>
            </Column>
          </Row>
        </div>
      </Container>
    </Section>
    <div className="home__carousel-container">
      <Section>
        <Container>
          <Row withDividers>
            <Column size={5} offset={1}>
              <Carousel
                settings={{
                  dots: true,
                }}
                cards={[
                  {
                    image: 'home-all-things-digital.svg',
                    title: 'All Things Digital',
                    text:
                      'Spark students’ connection to the digital world by asking what technology is, and envisioning what innovation can bring in the future. ',
                  },
                  {
                    image: 'home-cryptology.svg',
                    title: 'Cryptology',
                    text:
                      'Uncover the crucial importance of encryption for maintaining online safety. Cryptology relies on the problem-solving potential of mathematics!',
                  },
                  {
                    image: 'home-fraud-detection.svg',
                    title: 'Fraud Detection',
                    text:
                      'Embrace students’ natural powers of intuition and pattern recognition to help them understand how Fraud Detectives prevent data breaches.',
                  },
                  {
                    image: 'home-internet-of-things.svg',
                    title: 'Big Data',
                    text:
                      'Show students how valuable data is in today’s world by investigating the ways it can be used to improve businesses, lives, and communities.',
                  },
                  {
                    image: 'home-ai-social-good.svg',
                    title: 'A.I. for Social Good ',
                    text:
                      'Celebrate the game-changing capabilities of tech as a tool for the greater good. A.I. is like a super smart sidekick helping us solve complex problems!',
                  },
                ]}
              />
            </Column>
            <Column size={5} offset={1} className="home__explore-cybersecurity">
              <h2>Explore Cybersecurity</h2>
              <p>
                Blast-off into the five fundamental fields of Cybersecurity.
                Students can explore the amazing ways that their physical lives
                intersect with the ever-expanding digital world.
              </p>
              <Button to="/cybersecurity">
                Learn more about Cybersecurity
              </Button>
            </Column>
          </Row>
        </Container>
      </Section>
    </div>
    <CareerCallout
      title="Chief Security Officer"
      description={
        <>
          Discover the digital tools and STEM skills a real-world cybersecurity
          pro uses to fight <strong>cybercrime</strong> and{' '}
          <strong>hacking</strong> online.
        </>
      }
      path="/career-profiles/chief-security-officer"
      img={'career-profiles/career-profiles-dr-jay-headshot.jpg'}
      name="Alissa “Dr. Jay” Abdullah"
      job="Deputy Chief Security Officer, Mastercard"
    />
    <FooterCallout
      text="In the next few years, the number of devices connected to the
              Internet of Things is estimated to reach 25 billion."
      className="home__iot-fact"
    />
    <Girls4TechCallout />
  </Layout>
);

export default IndexPage;
