import React from 'react';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import OutboundLink from '../../components/OutboundLink';

const Girls4TechCallout = () => (
  <Section>
    <Container>
      <Row verticalAlignCenter>
        <Column size={3} offset={1}>
          <Image filename="program-logo.png" />
        </Column>
        <Column size={7}>
          <p>
            Explore additional cybersecurity resources dedicated to girls making
            a difference in STEM in more than 9 languages.
          </p>
          <OutboundLink
            to="https://www.girls4tech.com"
            withIcon
            className="text-bold"
          >
            Visit Girls4Tech.com
          </OutboundLink>
        </Column>
      </Row>
    </Container>
  </Section>
);

export default Girls4TechCallout;
